<i18n>
{
  "de": {
    "noScenarios": "Dieses Portfolio enthält keine Szenarien.",
    "resetButtonLabel": "Szenarien zurücksetzen",
    "addScenarioButton": "Szenario hinzufügen",
    "deleteConfirmation": "Wollen Sie Szenario \"{description}\" wirklich löschen?",
    "resetConfirmation": "Wollen Sie alle Szenarien löschen und die Standard-Szenarien wiederherstellen?",
    "copyOf": "Kopie von",
    "pendingLabel": "Änderungen werden gespeichert..."
  }
}
</i18n>

<template>
  <div class="c-portfolio-scenarios-page">
    <div v-if="error">{{ error }}</div>
    <div v-else-if="pending">{{ $t('pendingLabel') }}</div>
    <section v-else-if="portfolio">
      <NoDataMessage v-if="!portfolio.scenarios || portfolio.scenarios.length === 0" :message="$t('noScenarios')" />
      <div v-for="scenario in portfolio.scenarios" :key="scenario.id" class="scenario-item">
        <router-link
          :to="{ name: 'scenario', params: { portfolio_id: portfolio.id, scenario_id: scenario.id } }"
          class="scenario-link"
        >
          <ScenarioIcon :color="scenario.color" />{{ scenario.description }}
        </router-link>
        <ListButtonWrapper>
          <ListShowOrEditButton
            :allowEdit="getPortfolioPermission('EDIT_SCENARIOS')"
            @click="getPortfolioPermission('EDIT_SCENARIOS') ? onEditScenario(scenario) : onShowScenario(scenario)"
          />
          <ListDuplicateButton v-if="getPortfolioPermission('EDIT_SCENARIOS')" @click="onCopyScenario(scenario)" />
          <ListDuplicatePlusButton
            v-if="getPortfolioPermission('EDIT_SCENARIOS')"
            @click="onCopyScenarioWithMeasures(scenario)"
          />
          <ListDeleteButton
            v-if="getPortfolioPermission('EDIT_SCENARIOS') && hasMultipleScenarios"
            @click="onDeleteScenario(scenario)"
          />
        </ListButtonWrapper>
      </div>
      <ButtonWrapper>
        <Button
          v-if="getPortfolioPermission('EDIT_SCENARIOS')"
          icon="plus"
          :text="$t('addScenarioButton')"
          @click="onAddScenario()"
        />
        <Button
          v-if="getPortfolioPermission('EDIT_SCENARIOS')"
          :text="$t('resetButtonLabel')"
          @click="resetScenarios()"
        />
      </ButtonWrapper>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListDuplicateButton from '@/components/shared/lists/ListDuplicateButton.vue'
import ListDuplicatePlusButton from '@/components/shared/lists/ListDuplicatePlusButton.vue'
import ListShowOrEditButton from '../shared/lists/ListShowOrEditButton.vue'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import ScenarioIcon from '@/components/shared/ScenarioIcon.vue'
import NoDataMessage from '../shared/lists/NoDataMessage.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  components: {
    ListButtonWrapper,
    ListDeleteButton,
    ListDuplicateButton,
    ListDuplicatePlusButton,
    ListShowOrEditButton,
    ButtonWrapper,
    ScenarioIcon,
    NoDataMessage,
    Button,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: null,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    hasMultipleScenarios() {
      return this.portfolio.scenarios && this.portfolio.scenarios.length > 1
    },
  },

  methods: {
    ...mapActions({
      deleteScenario: 'portfolio/deleteScenario',
      addScenario: 'portfolio/addScenario',
      duplicateScenarioWithMeasures: 'portfolio/duplicateScenarioWithMeasures',
      resetScenarios: 'portfolio/resetScenarios',
      setEditMode: 'form/setEditMode',
    }),

    onShowScenario(scenario) {
      this.$router.push({ name: 'scenario', params: { portfolio_id: this.portfolio.id, scenario_id: scenario.id } })
    },

    onEditScenario(scenario) {
      this.setEditMode(true)
      this.$router.push({ name: 'scenario', params: { portfolio_id: this.portfolio.id, scenario_id: scenario.id } })
    },

    onAddScenario() {
      this.setEditMode(true)
      this.$router.push({ name: 'scenario', params: { portfolio_id: this.portfolio.id, scenario_id: -1 } })
    },

    async onCopyScenario(scenario) {
      // eslint-disable-next-line
      const { definition, description: d, portfolio_id } = scenario
      const description = `${this.$t('copyOf')} ${d}`
      this.pending = true
      try {
        this.error = null
        await this.addScenario({ definition, description, portfolio_id })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },

    async onCopyScenarioWithMeasures(scenario) {
      this.pending = true
      try {
        this.error = null
        await this.duplicateScenarioWithMeasures({
          scenarioId: scenario.id,
          portfolioId: this.portfolio.id,
          description: `${this.$t('copyOf')} ${scenario.description}`,
        })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },

    async onDeleteScenario(scenario) {
      const { description } = scenario
      const confirmationText = this.$t('deleteConfirmation', { description })
      if (confirm(confirmationText)) {
        this.pending = true
        try {
          this.error = null
          await this.deleteScenario(scenario)
        } catch (error) {
          this.error = error
        }
        this.pending = false
      }
    },

    async resetScenarios() {
      const confirmationText = this.$t('resetConfirmation')
      if (confirm(confirmationText)) {
        this.pending = true
        try {
          this.error = null
          await this.resetScenarios(this.portfolio.id)
        } catch (error) {
          this.error = error
        }
        this.pending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-portfolio-scenarios-page {
  & .scenario-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 50%;
    min-width: 500px;
    margin-top: var(--spacing-s);
    padding: var(--spacing-s);
    border: var(--box-border);
    border-radius: var(--box-radius);

    & .scenario-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-xs);
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>
